import { CommonUtil } from "../utils/common.utils";

/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class AuthUser {
    id: number;
    login: string;
    firstname: string;
    lastname: string;
    created_on: Date;
    last_login_on: Date;
    api_key: string;
    custom_fields: any[];
    email: string;
    userAvatar: string;
    fullname: string;
    team_user: string;
    can_invite_users: string;
    max_invited_user: number;
    language: string;
    global_mute: string;
    mail_notification: string;
    sound: string;
    notification: string;
    no_self_notified: string;
    bgAvatarColor: string;
  
    constructor(json) {
      this.id = json.id;
      this.login = json.login;
      this.firstname = json.firstname;
      this.lastname = json.lastname;
      this.fullname = json.firstname + " " + json.lastname;
      this.created_on = new Date(json.created_on);
      this.last_login_on = new Date(json.last_login_on);
      this.api_key = json.api_key;
      this.custom_fields = json.custom_fields;
      this.email = json.mail ? json.mail : json.email;
      this.userAvatar = json.userAvatar;
      this.team_user = json.team_user;
      this.can_invite_users = json.can_invite_users;
      this.max_invited_user = json.max_invited_user;
      this.language = json.language;
      this.global_mute = json.global_mute;
      this.mail_notification = json.mail_notification;
      this.sound = json.sound;
      this.notification = json.notification;
      this.no_self_notified = json.no_self_notified;
      this.bgAvatarColor = CommonUtil.getRandomAvatarColor();
    }
  }
  