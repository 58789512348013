
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef, ViewChild, ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRootState, getContactFolders } from "src/app/contacts/store";
import { Store } from "@ngrx/store";
import { ToastService } from "src/app/common/service/tost.service";
import { distinctUntilChanged, takeUntil, take } from "rxjs/operators";
import { ContactFolder } from "src/app/contacts/models/create-folder.model";
import { CreateFolderComponent } from "src/app/contacts/components/create-contact-folder/create-folder.component";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ContactListsOperationDialogComponent } from "../contact-lists-operation-dialog/contact-lists-operation-dialog.component";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { Router } from "@angular/router";
import { ListContactDialogComponent } from "../list-contact-dialog/list-contact-dialog.component";
import { RootState, getOnlineStatus } from "src/app/reducers";
import { SetRoutingType } from "src/app/actions/app";
import { AppConstants } from "src/app/common/utils/app-constants";

@Component({
    selector: "vp-contact-lists-dialog",
    templateUrl: "./contact-lists-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactListsDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    contactFolders: ContactFolder[] = [];
    isSearchView: boolean = false;
    isOnline: boolean;
    searchText: string = "";
    @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ContactListsDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private store: Store<ContactRootState | RootState>,
        private matDialog: MatDialog,
        private toastService: ToastService,
        private contactRepo: ContactRepository,
        private contactRepository: ContactRepository,
        private router: Router
    ) {
        this.store.dispatch(new SetRoutingType(AppConstants.LIST));
    }

    ngOnInit() {
        this.store.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: ContactFolder[]) => {
            this.contactFolders = contactFolders;
            this.changeDetectionRef.markForCheck();
        });
        this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
            this.isOnline = isOnline;
        });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_LISTS_DAILOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.contactRepository.setRouteTypeBasedonURL();
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openSearchView(value: boolean): void {
        if (value) {
            setTimeout(() => {
                this.searchTextInput.nativeElement.focus();
            }, 200);
        }
        this.isSearchView = value;
        this.changeDetectionRef.markForCheck();
    }

    addList(): void {
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }
        this.matDialog.open(CreateFolderComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "contact_folder-create_dialog",
            disableClose: true,
            data: { targetFolder: null, isRename: false }
        });
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectionRef.markForCheck();
    }

    close(): void {
        this.broadcaster.broadcast(BroadcastKeys.SHOW_HAMBURGER_MENU);
        this.dialogRef.close();
    }

    openListOperationMenu(list: ContactFolder): void {
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }

        const dialogRef = this.matDialog.open(ContactListsOperationDialogComponent, {
            width: "230px",
            height: "160px",
            autoFocus: true,
            panelClass: "contact-lists-operation-dialog"
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.operation) {
                const operation = res.operation;
                if (operation === "edit") {
                    this.matDialog.open(CreateFolderComponent, {
                        width: "325px",
                        height: "212px",
                        autoFocus: true,
                        panelClass: "contact_folder-create_dialog",
                        disableClose: true,
                        data: { targetFolder: list, isRename: true }
                    });
                } else if (operation === "delete") {
                    const dlg = this.matDialog.open(ConfirmationDialogComponent, {
                        maxWidth: "100%",
                        autoFocus: false,
                        panelClass: "confirm_contactplus_dialog",
                        data: { dialogType: ConfirmDialogType.DELETE_LIST, message_title: "DELETE_LIST", message_body: "CONFIRM_DELETE_FOLDER" }
                    });
                    dlg.afterClosed().pipe(take(1)).subscribe(res => {
                        if (!!res && res.confirmation) {
                            if (res.confirmation === "yes") {
                                this.contactRepo.deleteContactList(list);
                            }
                        }
                    });
                }
            }
        });
    }

    routeToContactListFolder(list: ContactFolder): void {
        this.contactRepository.getContactFromList(list.id.toString());
        this.matDialog.open(ListContactDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "list-contact-dialog",
            data: {listItem: list}
          });
    }
}