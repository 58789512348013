
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, NgZone, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil, map, take } from "rxjs/operators";
import { AppConstants } from "src/app/common/utils/app-constants";
import { ContactRepository } from "src/app/contacts/repository/contact.repository";
import { ConfigService } from "src/app/common/providers/config.service";
import { environment } from "src/environments/environment";
import { Http, Headers } from "@angular/http";
import { TFARepository } from "src/app/contacts/repository/tfa.repository";
import { TFASettingsComponent } from "../tfa-settings/tfa-settings.component";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Component({
    selector: "vp-contact-settings-dialog",
    templateUrl: "./contact-settings-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSettingsDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    selectedLanguage: string = "en";
    serverURL: string = "";
    showServerSettings = false;
    isUpdated: boolean = false;
    use2FA: boolean = false;
    is2FAEnabled: boolean;
    isSupport2FA: boolean = false;
    isCordova = environment.isCordova;
    constructor(
        public dialogRef: MatDialogRef<ContactSettingsDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectionRef: ChangeDetectorRef,
        private contactRepository: ContactRepository,
        private configService: ConfigService,
        private http: Http,
        private tfaRepo: TFARepository,
        private matDialog: MatDialog,
    ) {
        const storedLanguage = localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
        if (!!storedLanguage && storedLanguage !== null && storedLanguage !== "") {
            this.selectedLanguage = storedLanguage;
            this.changeDetectionRef.markForCheck();
        }
        if (environment.isCordova) {
            this.showServerSettings = true;
            this.changeDetectionRef.markForCheck();
        }

        if (localStorage.getItem(AppConstants.SERVER_URL) !== null) {
            this.serverURL = localStorage.getItem(AppConstants.SERVER_URL);
        }
        this.get2FAStatus();
        this.changeDetectionRef.markForCheck();
    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_SETTINGS_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    public get2FAStatus() {
        this.isSupport2FA = this.configService.two_factor_authentication;
        if (this.isSupport2FA) {
          this.tfaRepo.get2FAStatus().pipe(take(1)).subscribe( value => {
            if (value && value.tfa_enable) {
              this.is2FAEnabled = value.tfa_enable !== "false" ? true : false;
              this.use2FA = this.is2FAEnabled;
              this.changeDetectionRef.markForCheck();
            }
          });
        }
    }

    update2FAOptions() {
        if ( this.is2FAEnabled && !this.use2FA) {
          this.isUpdated = true;
        } else {
          this.isUpdated = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    saveSettings(): void {
        const body = {
            "language": this.selectedLanguage
        };
        const storedLanguage = localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
        if ( storedLanguage !== this.selectedLanguage) {
            this.contactRepository.saveUserSettings(body);
        }
        if (this.showServerSettings && this.configService.API_URL !== this.serverURL) {
            this.loginNewServer();
        }
        this.set2FASettings();
    }

    private set2FASettings() {
        if (this.isSupport2FA) {
          if (this.is2FAEnabled && !this.use2FA ) {
            this.tfaRepo.disableTFA().subscribe( res => {
              this.is2FAEnabled = false;
              this.changeDetectionRef.markForCheck();
              this.close();
            });
          }
        }
    }

    loginNewServer() {
        console.log("[environment]:", environment);
        const headers = new Headers({ "Content-Type": "application/json" });
        if (this.serverURL.endsWith("/")) {
          this.serverURL = this.serverURL.substring(0, this.serverURL.lastIndexOf("/")).trim();
        }
        console.log("[Server URL] Click : ", this.serverURL);
        this.http.get(this.serverURL + "/api/config", { headers: headers }).pipe(map(response => response.json()))
          .subscribe(data => {
            if (data.boshURL) {
              this.configService.API_URL = this.serverURL;
              this.setServerURL(this.configService.API_URL);
            } else {
              alert("The Server URL entered by you is wrong. Please provide correct URL");
            }
          }, () => {
            alert("The Server URL entered by you is wrong. Please provide correct URL");
          });
    }

    setServerURL(url) {
        if (url) {
          this.configService.setAPIURL(url);
          let serverURL;
          localStorage.clear();
          localStorage.setItem(AppConstants.SERVER_URL, url.trim());
          serverURL = localStorage.getItem(AppConstants.SERVER_URL);

          if (!!serverURL && serverURL !== null) {
            console.log("Server URL config redirect to Login screen");
            this.configService.selectedServer = true;
            const initialHref = CommonUtil.isOnAndroid() ? window.location.href.split("/www/")[0] : window.location.href.split("/contactplus/all")[0];
            window.location.href = `${initialHref}${CommonUtil.isOnAndroid() ? "/www" : ""}/index.html`;
            this.configService.loginIframe();
          }
        }
    }

    updateOptions() {
        this.isUpdated = true;
        this.changeDetectionRef.markForCheck();
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    setAuthenticationApp() {
      let dialogRef = this.matDialog.open(TFASettingsComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "contact_tfa_settings_dialog"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.is2FAEnabled = true;
          localStorage.removeItem("token");
          this.contactRepository.logout();
          this.changeDetectionRef.markForCheck();
        }
      });
    }
}
