
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ToastService {

    constructor(
        private matSnackBar: MatSnackBar,
        private translate: TranslateService
    ) {
    }

    show(translationKey: string, timeout = 4000) {
        this.translate.get(translationKey).subscribe(msg => {
            this.matSnackBar.open(msg, null, {
                duration: timeout,
                panelClass: "mobile_snackbar"
            });
        });
    }

    showPlainMessage(message: string, timeout = 4000) {
        this.matSnackBar.open(message, null, {
            duration: timeout,
            panelClass: "mobile_snackbar"
        });
    }
}
