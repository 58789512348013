
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { enableProdMode, ErrorHandler } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import * as Raven from "raven-js";

setupTheme();

if (environment.production) {
  enableProdMode();

    // window.console.log = function () {
    // };
    // window.console.debug = function () {
    // };

  Raven
     .config("https://038da7f66a46401c8fea5c5b45d59731@sentry-web.dev-k8s.vnc.de/4")
     .install();

  class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
      Raven.captureException(err.originalError);
    }
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);

function loadExternalStyle(styleUrl: string) {
  return new Promise((resolve, reject) => {
    let styleElement = document.createElement("link");
    styleElement.rel = "stylesheet";
    styleElement.type = "text/css";
    styleElement.href = styleUrl;
    styleElement.onload = function() {
      showApp();
      resolve(true);
    };
    document.head.appendChild(styleElement);
    styleElement = null;
  });
}

function loadExternalScript(scriptUrl: string) {
  return new Promise(resolve => {
    let scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
    scriptElement = null;
  });
}

function showApp() {
  document.querySelector("vp-app").removeAttribute("style");
}

function setupTheme() {
  if ( environment.isCordova ) {
    loadExternalScript("cordova.js");
    // remove non-cordova-div
  //  document.getElementById("non-cordova-only").outerHTML = "";
  } else {
   // document.getElementById("cordova-only").outerHTML = "";
  }
  document.title = environment.title;
}
