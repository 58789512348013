
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { environment } from "../environments/environment";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonUtil } from "./common/utils/common.utils";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { AuthService } from "./common/providers/auth.service";
import { ConfigService } from "./common/providers/config.service";
import { AvailableRoutes } from "./common/providers/route.guards";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { LocalStorageService } from "./common/providers/storage.service";
import { LocaleService } from "./common/providers/locale.service";
import { AuthGuard, AppRoutingModule } from "./app-routing.module";
import { StoreModule } from "@ngrx/store";
import { reducers, reset } from "./reducers";
import { ContactService } from "./common/service/contact-service";
import { HeaderComponent, HeaderService } from "./header";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ContactRepository } from "./contacts/repository/contact.repository";
import { ContextMenuModule } from "@perfectmemory/ngx-contextmenu";
import { ToastService } from "./common/service/tost.service";
import { ElectronService } from "./common/service/electron.service";
import { ResponsiveService } from "./common/providers/responsive.service";
import { FilesStorageService } from "./common/service/files-storage.service";
import { CreateContactDialogComponent } from "./contacts/components/create-contact-dialog/create-contact-dialog.component";
import { CreateTagComponent } from "./contacts/components/create-contact-tag-dialog/create-contact-tag.component";
import { CreateFolderComponent } from "./contacts/components/create-contact-folder/create-folder.component";
import { CreateActionGroupDialogComponent } from "./contacts/components/create-action-group-dialog/create-action-group-dialog.component";
import { UndoChangesDialogComponent } from "./contacts/components/undo-changes-dialog/undo-changes-dialog.component";
import { ImportContactDialogComponent } from "./contacts/components/import-contact-dialog/import-contact-dialog.component";
import { ExportContactDialogComponent } from "./contacts/components/export-contact-dialog/export-contact-dialog.component";
import { CreateContactGroupDailogComponent } from "./contacts/components/create-contact-group-dialog/create-contact-group-dialog.component";
import { ContactDetailDialogComponent } from "./contacts/components/contact-detail-dialog/contact-detail-dialog.component";
import { TFAService } from "./common/service/tfa.service";
import { TFARepository } from "./contacts/repository/tfa.repository";
import { RecentActivityDialogComponent } from "./contacts/components/recent-activity-dialog/recent-activity-dialog.component";
import { ShareContactEmailComponent } from "./contacts/components/share-contact-email-dialog/share-contact-email.component";
import { DatabaseService } from "./common/service/db/database.service";
import { ContactPlusListSelectionComponent } from "./contacts/components/contact-list-selection/contact-list-selection.component";

export function HttpLoaderFactory(http: HttpClient) {
    const bUrl = CommonUtil.getBaseUrl();
    const path = bUrl.endsWith("/") ? "assets/i18n/" : "/assets/i18n/";
  return new TranslateHttpLoader(http, CommonUtil.getBaseUrl() + path, ".json");
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        HttpModule,
        FlexLayoutModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        ContextMenuModule.forRoot({
            autoFocus: true
        }),
        SharedModule.forRoot(),
        StoreModule.forRoot(reducers, { metaReducers: [reset], runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            } }),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : []
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        CreateContactDialogComponent,
        CreateFolderComponent,
        CreateTagComponent,
        CreateActionGroupDialogComponent,
        UndoChangesDialogComponent,
        ExportContactDialogComponent,
        ImportContactDialogComponent,
        CreateContactGroupDailogComponent,
        ContactDetailDialogComponent,
        RecentActivityDialogComponent,
        ShareContactEmailComponent,
        ContactPlusListSelectionComponent
    ],
    providers: [
        AuthService,
        ConfigService,
        AuthGuard,
        AvailableRoutes,
        Broadcaster,
        DatabaseService,
        LocalStorageService,
        LocaleService,
        ContactService,
        HeaderService,
        FilesStorageService,
        ContactRepository,
        ToastService,
        ElectronService,
        ResponsiveService,
        DatePipe,
        TFAService,
        TFARepository
    ],
    exports: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}

