
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Contact } from "../models/contact.model";
import * as vCardJS from "vcards-js";
import { CommonUtil } from "./common.utils";

export class ContactUtils {
    static getContacts(contactItem: any): Contact {
        const contact = new Contact();
        contact.id = contactItem.id;
        if (contactItem.addresses && contactItem.addresses !== null && contactItem.addresses.length > 0) {
            contact.address = contactItem.addresses;
        }
        if (contactItem.avatar) {
            contact.avatar = contactItem.avatar;
        }
        if (contactItem.company) {
            contact.company = contactItem.company;
        }
        if (contactItem.created_at && contactItem.created_at !== null) {
            contact.created_at = new Date(contactItem.created_at);
        }
        if (contactItem.deleted_at && contactItem.deleted_at !== null) {
            contact.deleted_at = new Date(contactItem.deleted_at);
        }
        if (!!contactItem.emails_attributes && (contactItem.emails_attributes.length > 0)) {
            if (!contactItem.emails) {
                contactItem.emails = contactItem.emails_attributes;
            }
        }
        if (contactItem.emails) {
            contact.emails = contactItem.emails;
        }
        if (!contact.emails || contact.emails.length === 0) {
            contact.emails = [{email: contactItem.email || contactItem.jid}].filter(v => !!v?.email);
        }
        if (contactItem.first_name) {
            contact.firstName = contactItem.first_name;
        }
        if (contactItem.groups) {
            contact.groups = contactItem.groups;
        }
        if (contactItem.is_company) {
            contact.is_company = contactItem.is_company === "true" ? true : false;
        }
        if (contactItem.is_global) {
            contact.is_global = (contactItem.is_global === "true" || contactItem.is_global === true) ? true : false;
        }
        if (contactItem.contains_hr_data) {
            contact.contains_hr_data = contactItem.contains_hr_data;
        }
        if (contactItem.jid) {
            contact.jid = contactItem.jid;
        }
        if (contactItem.job_title) {
            contact.jobTitle = contactItem.job_title;
        }
        if (contactItem.last_name) {
            contact.lastName = contactItem.last_name;
        }
        if (contactItem.middle_name) {
            contact.middleName = contactItem.middle_name;
        }
        if (contactItem.phones) {
            contact.phones = contactItem.phones;
        }
        if (contactItem.updated_at && contactItem.updated_at !== null) {
            contact.updated_at = new Date(contactItem.updated_at);
        }
        if (contactItem.is_global) {
            contact.is_global = contactItem.is_global === "true" ? true : false;
        }
        if (contactItem.notes) {
            contact.notes = contactItem.notes;
        }
        if (contactItem.im_accounts && contactItem.im_accounts !== null && contactItem.im_accounts.length > 0) {
            contact.im_accounts = contactItem.im_accounts;
        }
        if (contactItem.urls) {
            contact.urls = contactItem.urls;
        }
        if (contactItem.custom_fields) {
            contact.custom_fields = contactItem.custom_fields;
        }
        if (contactItem.events && contactItem.events !== null && contactItem.events.length > 0) {
            contact.events = contactItem.events;
        }
        if (contactItem.contact_lists) {
            contact.contact_list = contactItem.contact_lists;
        }
        if (contactItem.tags) {
            contact.tags = contactItem.tags;
        }
        if (contactItem.time_zone && contactItem.time_zone !== null) {
            contact.timezone = contactItem.time_zone;
        }
        if (contactItem.language && contactItem.language !== null) {
            contact.language = contactItem.language;
        }
        if (contactItem.skills && contactItem.skills !== null && contactItem.skills.length > 0) {
            contact.skills = contactItem.skills;
        }
        if (contactItem.interests && contactItem.interests !== null && contactItem.interests.length > 0) {
            contact.interests = contactItem.interests;
        }
        if (contactItem.birthday && contactItem.birthday !== null) {
            contact.birthday = new Date(contactItem.birthday);
        }
        if (contactItem.gender && contactItem.gender !== null) {
            contact.gender = contactItem.gender;
        }
        if (contactItem.marital_status && contactItem.marital_status !== null) {
            contact.marital_status = contactItem.marital_status;
        }
        if (contactItem.private_email && contactItem.private_email !== null) {
            contact.private_email = contactItem.private_email;
        }
        if (contactItem.start_date && contactItem.start_date !== null) {
            contact.start_date = new Date(contactItem.start_date);
        }
        if (contactItem.end_date && contactItem.end_date !== null) {
            contact.end_date = new Date(contactItem.end_date);
        }
        if (contactItem.per_week_availability && contactItem.per_week_availability !== null) {
            contact.per_week_availability = contactItem.per_week_availability;
        }
        if (contactItem.hourly_rate && contactItem.hourly_rate !== null) {
            contact.hourly_rate = contactItem.hourly_rate;
        }
        if (!!contactItem.vnc_employee && contactItem.vnc_employee !== null) {
            contact.vnc_employee = contactItem.vnc_employee;
        }
        if (contactItem.payment_mode && contactItem.payment_mode !== null) {
            contact.payment_mode = contactItem.payment_mode;
        }
        if (contactItem.passport_expiry && contactItem.passport_expiry !== null) {
            contact.passport_expiry = new Date(contactItem.passport_expiry);
        }
        if (contactItem.rfc_limit && contactItem.rfc_limit !== null) {
            contact.rfc_limit = contactItem.rfc_limit;
        }
        if (contactItem.username && contactItem.username !== null) {
            contact.username = contactItem.username;
        }
        if (contactItem.admin && contactItem.admin !== null) {
            contact.admin = contactItem.admin;
        }
        if (contactItem.agb_accepted && contactItem.agb_accepted !== null) {
            contact.agb_accepted = contactItem.agb_accepted;
        }
        if (contactItem.video_bridge && contactItem.video_bridge !== null) {
            contact.video_bridge = contactItem.video_bridge;
        }
        if (contactItem.omemo && contactItem.omemo !== null) {
            contact.omemo = contactItem.omemo;
        }
        if (contactItem.id_number && contactItem.id_number !== null) {
            contact.national_id_number = contactItem.id_number;
        }
        if (contactItem.id_expiry && contactItem.id_expiry !== null) {
            contact.national_id_expiry = new Date(contactItem.id_expiry);
        }
        if (contactItem.products && contactItem.products !== null && contactItem.products.length > 0) {
            contact.products = contactItem.products;
        }
        if (contactItem.favorite && contactItem !== null) {
            contact.favorite = contactItem.favorite === "true" ? true : false;
        }
        contact.fullName = this.getFullName(contact.firstName, contact.lastName);
        contact.bgAvatarColor = CommonUtil.getRandomAvatarColor();
        return contact;
    }

    static getContactGroup(groupItem: any): Contact {
        const contact = new Contact();
        contact.id = groupItem.id;
        contact.fullName = groupItem.name;
        if (groupItem.created_at && groupItem.created_at !== null) {
            contact.created_at = new Date(groupItem.created_at);
        }
        if (groupItem.updated_at && groupItem.updated_at !== null) {
            contact.updated_at = new Date(groupItem.updated_at);
        }
        if (groupItem.avatar) {
            contact.avatar = groupItem.avatar;
        }
        contact.isGroup = true;
        contact.bgAvatarColor = CommonUtil.getRandomAvatarColor();
        return contact;
    }

    public static getFullName(firstName: string, lastName: string): string {
        if (firstName && lastName) {
            return firstName + " " + lastName;
        } else {
            if (firstName && firstName !== undefined) {
                return firstName;
            } else if (lastName && lastName !== undefined) {
                return lastName;
            }
        }
    }

    static compareValues(key, order = "asc") {
        return function (a, b) {
            if (!a.hasOwnProperty(key) ||
                !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === "string") ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === "string") ?
                b[key].toUpperCase() : b[key];


            let comparison = 0;
            if (varA === undefined) {
                comparison = 1;
            } else if (varB === undefined) {
                comparison = -1;
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === "desc") ?
                    (comparison * -1) : comparison
            );
        };
    }

    static getInitialContactDetailState() {
        return {
            first_name: "",
            last_name: "",
            middle_name: "",
            job_title: "",
            is_company: "0",
            company: "",

            firstLastCharacters: "",
            emails_attributes: [{ email: "" }],
            phones_attributes: [
                {
                    phone_type: "home",
                    phone: ""
                }
            ],
            im_accounts_attributes: [
                {
                    im_type: "XMPP",
                    im_id: ""
                }
            ],
            addresses_attributes: [
                {
                    address_type: "home",
                    city: "",
                    street1: "",
                    street2: "",
                    state: "",
                    postcode: "",
                    country_code: ""
                }
            ],
            urls_attributes: [
                {
                    url_type: "home",
                    url: ""
                }
            ],
            notes: "",
            custom_fields_attributes: [],
            events_attributes: [
                {
                    event_type: "birthday",
                    event_date: ""
                }
            ],
            contact_list_ids: [],
            tag_list: "",
            bgAvatarColor: "",
            favorite: false,
        };
    }

    static initializeEditContactDetailState(contact: Contact, contactDetails: any) {
        if (contact) {
            if (contact.firstName) {
                contactDetails.first_name = contact.firstName;
            }

            if (contact.lastName) {
                contactDetails.last_name = contact.lastName;
            }

            if (contact.middleName) {
                contactDetails.middle_name = contact.middleName;
            }

            if (contact.fullName) {
                contactDetails.fullName = contact.fullName;
            }

            if (contact.jobTitle) {
                contactDetails.job_title = contact.jobTitle;
            }

            if (contact.company) {
                contactDetails.company = contact.company;
            }
            if (contact.favorite) {
                contactDetails.favorite = contact.favorite;
            }

            if (contact.emails?.length > 0) {
                contactDetails.phones_attributes = [];
                contact.emails.forEach(value => {
                    contactDetails.emails_attributes.push({ email: value.email, id: value.id });
                });
            } else {
                contactDetails.emails_attributes = [{ email: "" }];
            }

            if (contact.phones?.length > 0) {
                contactDetails.phones_attributes = [];
                contact.phones.forEach(phone => {
                    contactDetails.phones_attributes.push({
                            phone_type: phone.phone_type ? phone.phone_type : "home",
                            phone: phone.phone,
                            id: phone.id,
                            country_code: phone.country_code
                        });
                });
            } else {
                contactDetails.phones_attributes = [
                    {
                        phone_type: "home",
                        phone: ""
                    }
                ];
            }

            if (contact.address && contact.address.length > 0) {
                contactDetails.addresses_attributes = [];
                contact.address.forEach(address => {
                    contactDetails.addresses_attributes.push(
                        {
                            address_type: address.address_type,
                            city: address.city,
                            street1: address.street1,
                            street2: address.street2,
                            state: address.state,
                            postcode: address.postcode,
                            country_code: address.country_code,
                            id: address.id
                        }
                    );
                });
            } else {
                contactDetails.addresses_attributes = [
                    {
                        address_type: "home",
                        city: "",
                        street1: "",
                        street2: "",
                        state: "",
                        postcode: "",
                        country_code: ""
                    }
                ];
            }

            if (contact.notes) {
                contactDetails.notes = contact.notes;
            }

            if (contact.im_accounts && contact.im_accounts.length > 0) {
                contactDetails.im_accounts_attributes = [];
                contact.im_accounts.forEach(im => {
                    contactDetails.im_accounts_attributes.push({
                            im_type: im.im_type ? im.im_type : "XMPP",
                            im_id: im.im_id,
                            id: im.id
                        });
                });
            } else {
                contactDetails.im_accounts_attributes = [
                    {
                        im_type: "XMPP",
                        im_id: ""
                    }
                ];
            }

            if (contact.urls?.length > 0) {
                contactDetails.urls_attributes = [];
                contact.urls.forEach(url => {
                    contactDetails.urls_attributes.push({
                            url_type: url.url_type ? url.url_type : "home",
                            url: url.url,
                            id: url.id
                        });
                });
            } else {
                contactDetails.urls_attributes = [
                    {
                        url_type: "home",
                        url: ""
                    }
                ];
            }
            if (contact.custom_fields && contact.custom_fields.length > 0) {
                contactDetails.custom_fields_attributes = [];
                contact.custom_fields.forEach(custom => {
                    contactDetails.custom_fields_attributes.push({
                        field_name: custom.field_name,
                        field_value: custom.field_value,
                        id: custom.id
                    });
                });
            } else {
                contactDetails.custom_fields_attributes = [];
            }
            if (contact.events && contact.events.length > 0) {
                contactDetails.events_attributes = [];
                contact.events.forEach(event => {
                    contactDetails.events_attributes.push({
                            event_type: event.event_type ? event.event_type : "birthday",
                            event_date: event.event_date,
                            id: event.id
                        });
                });
            } else {
                contactDetails.events_attributes = [
                    {
                        event_type: "birthday",
                        event_date: ""
                    }
                ];
            }
            if (contact.bgAvatarColor) {
                contactDetails.bgAvatarColor = contact.bgAvatarColor;
            }
        }
        contactDetails.id = contact.id;
        return contactDetails;
    }

    static initializeEditContactDetailApiDataState(contact: any, contactDetails: any) {
        if (contact) {
            if (contact.first_name) {
                contactDetails.first_name =  decodeURIComponent(escape(contact.first_name));
            }

            if (contact.last_name) {
                contactDetails.last_name = decodeURIComponent(escape(contact.last_name));
            }

            if (contact.middle_name) {
                contactDetails.middle_name = decodeURIComponent(escape(contact.middle_name));
            }

            if (contact.fullName) {
                contactDetails.fullName = decodeURIComponent(escape(contact.fullName));
            }

            if (contact.job_title) {
                contactDetails.job_title = decodeURIComponent(escape(contact.job_title));
            }

            if (contact.company) {
                contactDetails.company = decodeURIComponent(escape(contact.company));
            }
            if (contact.favorite) {
                contactDetails.favorite = decodeURIComponent(escape(contact.favorite));
            }

            if (contact.emails?.length > 0) {
                contactDetails.phones_attributes = [];
                contact.emails.forEach(value => {
                    contactDetails.emails_attributes.push({ email: decodeURIComponent(escape(value.email)), id: decodeURIComponent(escape(value.id)) });
                });
            } else {
                contactDetails.emails_attributes = [{ email: "" }];
            }

            if (contact.phones?.length > 0) {
                contactDetails.phones_attributes = [];
                contact.phones.forEach(phone => {
                    contactDetails.phones_attributes.push({
                            phone_type: phone.phone_type ? phone.phone_type : "home",
                            phone: phone.phone,
                            id: phone.id,
                            country_code: phone.country_code
                        });
                });
            } else {
                contactDetails.phones_attributes = [
                    {
                        phone_type: "home",
                        phone: ""
                    }
                ];
            }

            if (contact.address && contact.address.length > 0) {
                contactDetails.addresses_attributes = [];
                contact.address.forEach(address => {
                    contactDetails.addresses_attributes.push(
                        {
                            address_type: decodeURIComponent(escape(address.address_type)),
                            city: decodeURIComponent(escape(address.city)),
                            street1: decodeURIComponent(escape(address.street1)),
                            street2: decodeURIComponent(escape(address.street2)),
                            state: decodeURIComponent(escape(address.state)),
                            postcode: address.postcode,
                            country_code: decodeURIComponent(escape(address.country_code)),
                            id: decodeURIComponent(escape(address.id))
                        }
                    );
                });
            } else {
                contactDetails.addresses_attributes = [
                    {
                        address_type: "home",
                        city: "",
                        street1: "",
                        street2: "",
                        state: "",
                        postcode: "",
                        country_code: ""
                    }
                ];
            }

            if (contact.notes) {
                contactDetails.notes = decodeURIComponent(escape(contact.notes));
            }

            if (contact.im_accounts && contact.im_accounts.length > 0) {
                contactDetails.im_accounts_attributes = [];
                contact.im_accounts.forEach(im => {
                    contactDetails.im_accounts_attributes.push({
                            im_type: im.im_type ? im.im_type : "XMPP",
                            im_id: im.im_id,
                            id: im.id
                        });
                });
            } else {
                contactDetails.im_accounts_attributes = [
                    {
                        im_type: "XMPP",
                        im_id: ""
                    }
                ];
            }

            if (contact.urls?.length > 0) {
                contactDetails.urls_attributes = [];
                contact.urls.forEach(url => {
                    contactDetails.urls_attributes.push({
                            url_type: url.url_type ? url.url_type : "home",
                            url: url.url,
                            id: url.id
                        });
                });
            } else {
                contactDetails.urls_attributes = [
                    {
                        url_type: "home",
                        url: ""
                    }
                ];
            }
            if (contact.custom_fields && contact.custom_fields.length > 0) {
                contactDetails.custom_fields_attributes = [];
                contact.custom_fields.forEach(custom => {
                    contactDetails.custom_fields_attributes.push({
                        field_name: decodeURIComponent(escape(custom.field_name)),
                        field_value: decodeURIComponent(escape(custom.field_value)),
                        id: decodeURIComponent(escape(custom.id))
                    });
                });
            } else {
                contactDetails.custom_fields_attributes = [];
            }
            if (contact.events && contact.events.length > 0) {
                contactDetails.events_attributes = [];
                contact.events.forEach(event => {
                    contactDetails.events_attributes.push({
                            event_type: event.event_type ? event.event_type : "birthday",
                            event_date: event.event_date,
                            id: event.id
                        });
                });
            } else {
                contactDetails.events_attributes = [
                    {
                        event_type: "birthday",
                        event_date: ""
                    }
                ];
            }
            if (contact.bgAvatarColor) {
                contactDetails.bgAvatarColor = contact.bgAvatarColor;
            }
        }
        contactDetails.id = contact.id;
        return contactDetails;
    }

    static getAddressTypes(addressType: any): any[] {
        let addressTypes: any[] = [];
        addressTypes.push({ value: "home", viewValue: addressType.HOME });
        addressTypes.push({ value: "work", viewValue: addressType.WORK });
        addressTypes.push({ value: "other", viewValue: addressType.OTHER });
        return addressTypes;
    }

    static validateMail(mail): boolean {
        if (/^[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+(\.[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+)*@[A-Za-z0-9]+([-.][A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/.test(mail)) {
          return true;
        }
        return false;
    }

    static getVCFData(contact: Contact[]): string {
        let str: string = "";
        contact.forEach(contactItem => {
        let vCard = vCardJS();
        if (contactItem.firstName) {
            vCard.firstName = contactItem.firstName;
        }
        if (contactItem.lastName) {
            vCard.lastName = contactItem.lastName;
        }
        if (contactItem.company) {
            vCard.isOrganization = true;
            vCard.organization = contactItem.company;
        }
        if (contactItem.jobTitle) {
            vCard.role = contactItem.jobTitle;
        }
        if (!!contactItem.emails && contactItem.emails.length > 0) {
            vCard.workEmail = contactItem.emails.map(e => e.email);
        }
        if (!!contactItem.address && contactItem.address.length > 0) {
            contactItem.address.forEach(address => {
                if (address.address_type === "home") {
                    vCard.homeAddress.label = "Home Address";
                    vCard.homeAddress.street = address.street1;
                    vCard.homeAddress.city = address.city;
                    vCard.homeAddress.stateProvince = address.state;
                    vCard.homeAddress.postalCode = address.postcode;
                    vCard.homeAddress.countryRegion = address.country_code;
                }
                if (address.address_type === "work") {
                    vCard.workAddress.label = "Work Address";
                    vCard.workAddress.street = address.street1;
                    vCard.workAddress.city = address.city;
                    vCard.workAddress.stateProvince = address.state;
                    vCard.workAddress.postalCode = address.postcode;
                    vCard.workAddress.countryRegion = address.country_code;
                }
            });
            }
            const mobilePhone = [];
            const homePhone = [];
            const workPhone = [];
            if (!!contactItem.phones && contactItem.phones.length > 0) {
                contactItem.phones.forEach(phone => {
                    if (phone.phone_type === "mobile") {
                        mobilePhone.push(phone.phone);
                    }
                    if (phone.phone_type === "work") {
                        workPhone.push(phone.phone);
                    }
                    if (phone.phone_type === "home") {
                        homePhone.push(phone.phone);
                    }
                });
            }
            if (mobilePhone.length > 0) {
                vCard.cellPhone = mobilePhone;
            }
            if (homePhone.length > 0) {
                vCard.homePhone = homePhone;
            }
            if (workPhone.length > 0) {
                vCard.workPhone = workPhone;
            }
            if (contactItem.notes && contactItem.notes !== "") {
                vCard.note = contactItem.notes;
            }
            if (!!contactItem.phones && contactItem.urls.length > 0) {
                vCard.url = contactItem.urls.map( c => c.url);
            }
            vCard.photo.attachFromUrl(contactItem.avatar, "JPEG");
            str += vCard.getFormattedString();
        });
        console.log("[VCardData]: ", str);
        return str;
    }

   static getAddress(address: any): string {
        const userAddress: string[] = [];
        if (address.street1 && address.street1 !== null && address.street1 !== "") {
            userAddress.push(address.street1);
        }
        if (address.city && address.city !== null && address.city !== "") {
            userAddress.push(address.city);
        }
        if (address.state && address.state !== null && address.state !== "") {
            userAddress.push(address.state);
        }
        if (address.postcode && address.postcode !== null && address.postcode !== "") {
            userAddress.push(address.postcode);
        }
        if (address.country_code && address.country_code !== null && address.country_code !== "") {
            userAddress.push(address.country_code);
        }
        return userAddress.join(", ");
    }
}